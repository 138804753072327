import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axiosConfig/axiosConfig';
import { GetResponse } from '../axiosConfig/types';

// eslint-disable-next-line import/prefer-default-export
export const getProducts = createAsyncThunk<
  GetResponse<'/products'>,
  void,
  {
    rejectValue: string;
  }
>('product/getProducts', async (_args, { rejectWithValue }) => {
  try {
    const response = await axios.get('/products');
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});
