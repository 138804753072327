import React, { FC } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorPage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    errorPaper: {
      padding: theme.spacing(4),
    },
  }),
);

type ErrorPageProps = {
  error: Error | undefined;
};

const Error: FC<ErrorPageProps> = ({ error }): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.errorPage}>
      <Paper className={classes.errorPaper}>
        <Typography variant="h4" component="h4" align="center" paragraph>
          Oops, something went wrong...
        </Typography>
        <Typography variant="h6" component="h6" align="center">
          Error message:
          {error}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Error;
