import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    fallback: {
      minHeight: 'calc(100vh - 64px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const Fallback = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.fallback}>
      <CircularProgress size={120} />
    </Box>
  );
};

export default Fallback;
