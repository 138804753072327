import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../thunks/authThunk';
import { getProducts } from '../thunks/productsThunk';
import type { ProductsState } from './productsSlice.types';

const initialState: ProductsState = {
  loading: false,
  errorMessage: '',
  showAlert: false,
  data: [],
};

const productsSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    clearProducts: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.data = payload;
    });
    builder.addCase(getProducts.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // LOGOUT **************************************************************************************
    builder.addCase(logout.fulfilled, (state) => initialState);
  },
});

export const { clearProducts } = productsSlice.actions;

export default productsSlice.reducer;
