import React, { lazy, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Box, Container, createStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import appRoutes from '../../constants/appRoutes';
import Home from '../../pages/Home';
import Fallback from '../Fallback/Fallback';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import type { RootState } from '../../store/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      padding: theme.spacing(4, 0),
      backgroundColor: theme.palette.grey[100],
    },
    routes: {
      minHeight: 'calc(100vh - 64px)',
    },
  }),
);

// const LazyProduct = lazy(() => import('../../pages/Product'));
const LazyPricing = lazy(() => import('../../pages/Pricing'));
// const LazyContacts = lazy(() => import('../../pages/Contacts'));
const LazyDownloads = lazy(() => import('../../pages/Downloads'));
const LazyLogin = lazy(() => import('../../pages/Login'));
const LazyRegistration = lazy(() => import('../../pages/Registration'));
const LazyForgotPassword = lazy(() => import('../../pages/ForgotPassword'));
const LazyCheckEmail = lazy(() => import('../../pages/CheckEmail'));
const LazyResetPassword = lazy(() => import('../../pages/ResetPassword'));
const LazyDashboard = lazy(() => import('../../pages/Dashboard'));
const LazyCheckout = lazy(() => import('../Checkout/Checkout'));
const LazyPrivacy = lazy(() => import('../Privacy/Privacy'));
const LazyTerms = lazy(() => import('../Terms/Terms'));
const LazyNotFound404 = lazy(() => import('../../pages/NotFound404'));

export default function Routes(): JSX.Element {
  const classes = useStyles();

  const { router } = useSelector((store: RootState) => store);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'smooth',
    });
  }, [router.location.pathname]);

  return (
    <Box component="section" className={classes.body}>
      <Container className={classes.routes}>
        <React.Suspense fallback={<Fallback />}>
          <Switch>
            <Route exact path={appRoutes.HOME.url} component={Home} />
            {/* <Route exact path={appRoutes.PRODUCT.url} component={LazyProduct} /> */}
            <Route exact path={appRoutes.PRICING.url} component={LazyPricing} />
            <Route exact path={appRoutes.CHECKOUT.url} component={LazyCheckout} />
            {/* <Route exact path={appRoutes.CONTACTS.url} component={LazyContacts} /> */}
            <Route exact path={appRoutes.DOWNLOADS.url} component={LazyDownloads} />
            <Route exact path={appRoutes.LOGIN.url} component={LazyLogin} />
            <Route exact path={appRoutes.REGISTRATION.url} component={LazyRegistration} />
            <Route exact path={appRoutes.FORGOT_PASSWORD.url} component={LazyForgotPassword} />
            <Route exact path={appRoutes.CHECK_EMAIL.url} component={LazyCheckEmail} />
            <Route exact path={appRoutes.RESET_PASSWORD.url} component={LazyResetPassword} />
            <Route exact path={appRoutes.PRIVACY.url} component={LazyPrivacy} />
            <Route exact path={appRoutes.TERMS.url} component={LazyTerms} />
            <PrivateRoute appRoute={appRoutes.DASHBOARD} path={appRoutes.DASHBOARD.url} component={LazyDashboard} />
            <Route path="*" component={LazyNotFound404} />
          </Switch>
        </React.Suspense>
      </Container>
    </Box>
  );
}
