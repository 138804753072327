import { AccountRolesType } from '../store/slices/authSlice.types';

type BaseAppRoute = {
  url: Readonly<string>;
  roles: Readonly<AccountRolesType>;
  freeUserAccess?: Readonly<boolean>;
};

type AppRoutes = typeof appRoutes;
type AppRoute = AppRoutes[keyof AppRoutes];

type AppRouteKeys = {
  [k in keyof AppRoutes]: keyof AppRoutes[k];
}[keyof AppRoutes];

type OneWay = keyof BaseAppRoute extends AppRouteKeys ? true : false;
type OtherWay = AppRouteKeys extends keyof BaseAppRoute ? true : false;

/**
 * All of this ensures that we don't add any keys to appRoutes that shouldn't be there
 * We need to be able to do that without giving appRoutes an explicit type...
 * ...because that would clobber all the route-specific info we get when we use it
 */
const CompileTypeGuard: OneWay extends OtherWay ? true : false = true;

const appRoutes = {
  HOME: {
    url: '/',
    roles: [],
  },
  PRODUCT: {
    url: '/product',
    roles: [],
  },
  PRICING: {
    url: '/pricing',
    roles: [],
  },
  CONTACTS: {
    url: '/contacts',
    roles: [],
  },
  DOWNLOADS: {
    url: '/downloads',
    roles: [],
  },
  LOGIN: {
    url: '/login',
    roles: [],
  },
  REGISTRATION: {
    url: '/registration',
    roles: [],
  },
  FORGOT_PASSWORD: {
    url: '/forgot-password',
    roles: [],
  },
  CHECK_EMAIL: {
    url: '/check-email',
    roles: [],
  },
  RESET_PASSWORD: {
    url: '/reset-password',
    roles: [],
  },
  CHECKOUT: {
    url: '/checkout',
    roles: [],
  },
  PRIVACY: {
    url: '/privacy',
    roles: [],
  },
  TERMS: {
    url: '/terms',
    roles: [],
  },
  DASHBOARD: {
    url: '/dashboard',
    roles: [],
  },
  DASHBOARD_SETTINGS: {
    url: '/dashboard/settings',
    roles: [],
  },
  DASHBOARD_ACCOUNTS: {
    url: '/dashboard/accounts',
    roles: ['admin'],
  },
  DASHBOARD_WORKSPACES: {
    url: '/dashboard/workspaces',
    roles: ['admin', 'workspace'],
  },
  DASHBOARD_BILLING: {
    url: '/dashboard/billing',
    roles: ['billing'],
    freeUserAccess: true,
  },
  LAUNCH_APP: {
    url: process.env.REACT_APP_WEBAPP_URL,
    roles: ['developer'],
    freeUserAccess: true,
  },
} as const;

export type { AppRoute, AppRoutes };
export default appRoutes;
