import { Box, CircularProgress, Grid, Icon, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowRightBold } from 'mdi-material-ui';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import GithubAuthButton from '../components/Github/GithubAuthButton';
import githubAuthImg from '../images/demo/github_auth.png';
import githubImportImg from '../images/demo/github_import.png';
import importedWorkspaceImg from '../images/demo/imported_workspace.png';
import structuredValueEditorImg from '../images/demo/structured_value_editor.png';
import dynamicValueEditorImg from '../images/demo/dynamic_value_editor.png';
import dynamicValueEditorErrorImg from '../images/demo/dynamic_value_editor_error.png';
import fieldAnyUnpackedImg from '../images/demo/field_any_unpacked.png';
import fieldAnyPackedImg from '../images/demo/field_any_packed.png';
import { RootState } from '../store/store';

const useStyles = makeStyles((theme) => ({
  home: {
    // textAlign: 'center',
  },
  demo: {
    'max-width': '100%',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  github: {
    margin: theme.spacing(1, 0),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16,
  },
  githubGrid: {
    spacing: 1,
    alignItems: 'stretch',
    justifyContent: 'center',
    border: 'solid',
    backgroundColor: theme.palette.divider,
  },
  structuredValueEditorGrid: {
    spacing: 1,
    // alignItems: 'stretch',
    justifyContent: 'center',
    border: 'solid',
    backgroundColor: theme.palette.divider,
    width: 'fit-content',
  },
  dynamicValueEditorGrid: {
    spacing: 1,
    justifyContent: 'center',
    border: 'solid',
    backgroundColor: theme.palette.divider,
  },
  githubGridItem: {
    border: 'solid',
  },
  hr: {
    height: 1,
    width: '100%',
    border: 'none',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[100],
  },
}));

const Home: FC = (): JSX.Element => {
  const classes = useStyles();

  const { auth } = useSelector((store: RootState) => store);

  return (
    <Box mt={4} className={classes.home}>
      <Typography component="h3" variant="h3" color="textPrimary" align="center">
        The only gRPC & protobuf API client that handles the annoying bits.
      </Typography>
      <Typography variant="h5" align="justify" gutterBottom>
        <p />
        gRPC has a lot going on. All you want is to test a single rpc, but now you need to specify import paths? A
        ten-second task turns into a slog through documentation and error messages.
        <p />
        The command line clearly wasn&apos;t meant for this.
        <p />
        Import your protos from Github with zero setup, and Protocall will automatically resolve the imports:
        <p />
        <GithubAuthButton
          id="btnGithubLogin"
          variant="contained"
          color="primary"
          size="large"
          disabled={auth.login.loading}
          className={classes.github}
        >
          Login with Github
        </GithubAuthButton>
        {'  (Try it for free - no credit card required, and the free plan never expires.)'}
        {auth.login.loading && <CircularProgress size={32} className={classes.buttonProgress} />}
        <Grid container spacing={1} className={classes.githubGrid}>
          <Grid item xs={3}>
            1. <img src={githubAuthImg} className={classes.demo} alt="githubAuthImage" />
          </Grid>
          <Grid item xs={1} className={classes.arrow}>
            <Icon id="stepOneArrow">
              <ArrowRightBold />
            </Icon>
          </Grid>
          <Grid item xs={3}>
            2. <img src={githubImportImg} className={classes.demo} alt="githubImportImage" />
          </Grid>
          <Grid item xs={1} className={classes.arrow}>
            <Icon id="stepOneArrow">
              <ArrowRightBold />
            </Icon>
          </Grid>
          <Grid item xs={3}>
            3. <img src={importedWorkspaceImg} className={classes.demo} alt="importedWorkspaceImage" />
          </Grid>
        </Grid>
        <hr className={classes.hr} />
        <p />
        You probably get tired of tabbing between windows to check how to spell that field name.
        <p />
        Protocall will automatically generated structured inputs for your messages:
        <p />
        <Grid container spacing={1} className={classes.structuredValueEditorGrid}>
          <Grid item xs={12}>
            <img src={structuredValueEditorImg} className={classes.demo} alt="structuredValueEditorImage" />
          </Grid>
        </Grid>
        <p />
        Or you can use the dynamic editor, which still ensures you&apos;re creating a valid message:
        <p />
        <Grid container spacing={1} className={classes.dynamicValueEditorGrid}>
          <Grid item xs={6}>
            <img src={dynamicValueEditorImg} className={classes.demo} alt="dynamicValueEditorImage" />
          </Grid>
          <Grid item xs={6}>
            <img src={dynamicValueEditorErrorImg} className={classes.demo} alt="dynamicValueEditorErrorImage" />
          </Grid>
        </Grid>
        <hr className={classes.hr} />
        <p />
        Protocall supports difficult-to-test proto3 functionality, such as dynamic resolution of Any fields based on
        their type URL:
        <p />
        <Grid container spacing={1} className={classes.dynamicValueEditorGrid}>
          <Grid item xs={6}>
            <img src={fieldAnyUnpackedImg} className={classes.demo} alt="fieldAnyUnpackedImage" />
          </Grid>
          <Grid item xs={6}>
            <img src={fieldAnyPackedImg} className={classes.demo} alt="fieldAnyPackedImage" />
          </Grid>
        </Grid>
      </Typography>
    </Box>
  );
};

export default Home;
