import { Button, ButtonProps } from '@material-ui/core';
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Github, React } from 'mdi-material-ui';
import { useDispatch } from 'react-redux';
import { loginWithGithub } from '../../store/thunks/authThunk';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     submit: {
//       margin: theme.spacing(1, 0),
//     },
//   }),
// );

const GithubAuthButton = (props: ButtonProps): JSX.Element => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  function onLoginWithGithubClick() {
    dispatch(loginWithGithub());
  }

  return (
    <Button
      // id="btnGithubLogin"
      // fullWidth
      // variant="contained"
      // color="primary"
      // size="large"
      // className={classes.submit}
      {...props}
      startIcon={<Github />}
      onClick={onLoginWithGithubClick}
    >
      {/* Login with Github */}
    </Button>
  );
};

export default GithubAuthButton;
