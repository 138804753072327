import './App.css';
import React, { FC, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Fab, useScrollTrigger, Zoom } from '@material-ui/core';
import { ArrowUp } from 'mdi-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Header from './components/Header/Header';
import Routes from './components/Routes/Routes';
import Footer from './components/Footer/Footer';
import { checkAuth } from './store/thunks/authThunk';
import { RootState } from './store/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(4),
    },
  }),
);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

interface Props {
  // eslint-disable-next-line react/require-default-props
  window?: () => Window;
  children: React.ReactElement;
}

function ScrollTop({ children, window }: Props) {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

const App: FC<{ history: History }> = ({ history }): JSX.Element => {
  const dispatch = useDispatch();

  const { auth } = useSelector((store: RootState) => store);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if ('tidioChatApi' in window && auth.user.account_id) {
      const visitorData = {
        distinct_id: auth.user.account_id,
        email: auth.user.email,
        tags: [`accountId: ${auth.user.account_id}`],
      };

      if (auth.user.github_username) {
        visitorData.tags.push(`githubUsername: ${auth.user.github_username}`);
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore tidio integration goes on window...
      window.tidioChatApi.setVisitorData(visitorData);
    }
  }, [auth.user]);

  return (
    <ErrorBoundary>
      <ConnectedRouter history={history}>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Header />
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <Routes />
          </Elements>
          <Footer />
        </Box>
      </ConnectedRouter>
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <ArrowUp />
        </Fab>
      </ScrollTop>
    </ErrorBoundary>
  );
};

export default App;
