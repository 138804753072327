import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updatePassword } from '../thunks/authThunk';
import { createNewAccount, deactivateAccount, reactivateAccount } from '../thunks/dashboardThunk';
import { AlertPayloadType, AlertState } from './alertSlice.types';

const initialState: AlertState = {
  open: false,
  message: '',
  severity: 'error',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setOpenAlert: (state, { payload }: PayloadAction<AlertPayloadType>) => {
      const { message, severity } = payload;
      state.open = true;
      state.message = message;
      state.severity = severity;
    },
    setCloseAlert: (state) => {
      state.open = false;
      state.message = '';
    },
    clearProducts: () => initialState,
  },
  extraReducers: (builder) => {
    // CREATE-NEW-ACCOUNT **************************************************************************
    builder.addCase(createNewAccount.fulfilled, (state, { meta }) => {
      state.open = true;
      state.message = `Account name ${meta.arg.email} created successfully!`;
      state.severity = 'success';
    });
    builder.addCase(createNewAccount.rejected, (state, { payload }) => {
      state.open = true;
      state.message = payload ?? 'Something went wrong.';
      state.severity = 'error';
    });
    // DEACTIVATE-ACCOUNT **************************************************************************
    builder.addCase(deactivateAccount.fulfilled, (state, { payload, meta }) => {
      const { inactive_accounts } = payload;
      const accObj = inactive_accounts.find((a) => a.account_id === meta.arg.id);
      state.open = true;
      state.message = accObj
        ? `Account name ${accObj.email} deactivated successfully!`
        : 'Account successfully deactivated!';
      state.severity = 'success';
    });
    builder.addCase(deactivateAccount.rejected, (state, { payload }) => {
      state.open = true;
      state.message = payload ?? 'Something went wrong.';
      state.severity = 'error';
    });
    // REACTIVATE-ACCOUNT **************************************************************************
    builder.addCase(reactivateAccount.fulfilled, (state, { payload, meta }) => {
      const { accounts } = payload;
      const accObj = accounts.find((a) => a.account_id === meta.arg.id);
      state.open = true;
      state.message = accObj
        ? `Account name ${accObj.email} activated successfully!`
        : 'Account successfully activated!';
      state.severity = 'success';
    });
    builder.addCase(reactivateAccount.rejected, (state, { payload }) => {
      state.open = true;
      state.message = payload ?? 'Something went wrong.';
      state.severity = 'error';
    });
    // UPDATE-PASSWORD *****************************************************************************
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.open = true;
      state.message = 'Password updated successfully!';
      state.severity = 'success';
    });
  },
});

export const { setOpenAlert, setCloseAlert, clearProducts } = alertSlice.actions;

export default alertSlice.reducer;
