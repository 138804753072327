import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import axios from '../axiosConfig/axiosConfig';
import { GetResponse, PostResponse } from '../axiosConfig/types';
import appRoutes from '../../constants/appRoutes';
import type { AccountType, IForgotPassword, IResetPassword, SignInCredentials } from '../slices/authSlice.types';
import type { Store } from '../store';
import type { ISignUpCredentials } from './authThunk.types';

export const registration = createAsyncThunk<
  {
    sessionToken: PostResponse<'/login'>['sessionToken'];
    user: AccountType;
  },
  ISignUpCredentials,
  {
    rejectValue: string;
  }
>('auth/registration', async (credentials: ISignUpCredentials, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/account/native', credentials);
    let user: AccountType = {
      account_id: '',
      roles: [],
      sub_tier: 'team',
      email: '',
      github_username: '',
    };
    if (response.status === 200) {
      const profileResponse = await axios.post('/get-profile', { sessionToken: response.data.sessionToken });
      if (profileResponse.status === 200) {
        user = profileResponse.data;
      }
    }
    if (window.location.pathname === '/registration') {
      dispatch(push(appRoutes.DASHBOARD.url));
    } else {
      dispatch(push(window.location.pathname));
    }
    return { ...response.data, user };
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const login = createAsyncThunk<
  {
    sessionToken: PostResponse<'/login'>['sessionToken'];
    user: AccountType;
  },
  SignInCredentials,
  {
    rejectValue: string;
  }
>('auth/login', async (credentials: SignInCredentials, { rejectWithValue, dispatch }) => {
  try {
    const authResponse = await axios.post('/login', credentials);
    let user: AccountType = {
      account_id: '',
      roles: [],
      sub_tier: 'team',
      email: '',
      github_username: '',
    };
    if (authResponse.status === 200) {
      const profileResponse = await axios.post('/get-profile', { sessionToken: authResponse.data.sessionToken });
      if (profileResponse.status === 200) {
        user = profileResponse.data;
      }
    }
    if (window.location.pathname === '/login') {
      dispatch(push(appRoutes.DASHBOARD.url));
    } else {
      dispatch(push(window.location.pathname));
    }
    return { ...authResponse.data, user };
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const loginWithGithub = createAsyncThunk<
  PostResponse<'/account/github'>,
  void,
  {
    rejectValue: string;
  }
>('auth/github', async (_args, { rejectWithValue }) => {
  try {
    const response = await axios.post('/account/github', {});
    window.location.href = response.data.redirect;
    return response.data;
  } catch (err) {
    if (err.response) {
      return rejectWithValue(err.response.data.message);
    }
    return rejectWithValue(err.message);
  }
});

export const checkAuth = createAsyncThunk<
  {
    sessionToken: GetResponse<'/check-auth'>['sessionToken'];
    user: AccountType;
  },
  void,
  {
    rejectValue: string;
  }
>('auth/checkAuth', async (_arg, { rejectWithValue }) => {
  try {
    const authResponse = await axios.get('/check-auth');
    let user: AccountType = {
      account_id: '',
      roles: [],
      sub_tier: 'team',
      email: '',
      github_username: '',
    };
    if (authResponse.status === 200) {
      const profileResponse = await axios.post('/get-profile', { sessionToken: authResponse.data.sessionToken });
      if (profileResponse.status === 200) {
        user = profileResponse.data;
      }
    }
    return { ...authResponse.data, user };
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const logout = createAsyncThunk<
  PostResponse<'/logout'>,
  void,
  {
    rejectValue: string;
  }
>('auth/logout', async (_arg, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/logout', {});
    if (response.status === 200) dispatch(push('/login'));
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const forgotPassword = createAsyncThunk<
  PostResponse<'/forgot'>,
  IForgotPassword,
  {
    rejectValue: string;
  }
>('auth/forgot', async (credentials: IForgotPassword, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/forgot', credentials);
    dispatch(push('/check-email'));
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const resetPassword = createAsyncThunk<
  PostResponse<'/reset'>,
  IResetPassword,
  {
    rejectValue: string;
  }
>('auth/reset', async (data: IResetPassword, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post('/reset', data);
    dispatch(push('/login'));
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const updatePassword = createAsyncThunk<
  PostResponse<'/update-password'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('auth/updatePassword', async (_args, { rejectWithValue, getState }) => {
  try {
    const { auth } = getState();
    const response = await axios.post('/update-password', {
      ...auth.updatePassword.passwords,
      sessionToken: auth.sessionToken,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});
