import * as React from 'react';
import { AppBar, Button, createStyles, makeStyles, Theme, Toolbar } from '@material-ui/core';
import { Email, Github, Twitter } from 'mdi-material-ui';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      zIndex: 0,
    },
    footerToolBar: {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      justifyContent: 'space-around',
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightBold,
    },
    link: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(1),
      textDecoration: 'none',
      transition: 'color 0.2s ease',
      '&:hover': {
        color: theme.palette.secondary.light,
      },
    },
  }),
);

const Footer: FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="primary" component="footer" className={classes.footer}>
      <Toolbar className={classes.footerToolBar}>
        <div>
          <Button startIcon={<Twitter htmlColor="white" />} href="https://twitter.com/ProtocallEng" />
          <Button startIcon={<Github htmlColor="white" />} href="https://github.com/ProtocallDev/protocall" />
          <Button startIcon={<Email htmlColor="white" />} href="mailto:support@protocall.dev" />

          {/* <Link to="/" className={classes.link}>
            Home1
          </Link>
          <Link to="/" className={classes.link}>
            Home2
          </Link>
          <Link to="/" className={classes.link}>
            Home3
          </Link> */}
        </div>
        {/* <div>
          <Link to="/about" className={classes.link}>
            About1
          </Link>
          <Link to="/about" className={classes.link}>
            About2
          </Link>
          <Link to="/about" className={classes.link}>
            About3
          </Link>
        </div>
        <div>
          <Link to="/contacts" className={classes.link}>
            Contacts1
          </Link>
          <Link to="/contacts" className={classes.link}>
            Contacts2
          </Link>
          <Link to="/contacts" className={classes.link}>
            Contacts3
          </Link>
        </div> */}
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
