import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProducts } from '../thunks/productsThunk';
// eslint-disable-next-line import/no-cycle
import { attachCustomerSubscription, createCustomerSubscription } from '../thunks/purchaseThunk';
import { logout } from '../thunks/authThunk';
import type { BillingCycleType, PurchaseState, RolesType } from './purchaseSlice.types';

const initialState: PurchaseState = {
  loading: false,
  errorMessage: '',
  showAlert: false,
  activeStep: 0,
  data: {
    billingCycle: 'monthly',
    planType: '',
    emailAdmin: '',
    emailBilling: '',
    name: '',
    productId: '',
    priceId: '',
    quantity: '5',
    role: 'administrator',
    privacy: false,
  },
  subscription: null,
};

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    nextStep: (state) => {
      state.activeStep += 1;
    },
    prevStep: (state) => {
      state.activeStep -= 1;
    },
    resetStep: (state) => {
      state.activeStep = 0;
    },
    setBillingCycle: (state, { payload }: PayloadAction<BillingCycleType>) => {
      state.data.billingCycle = payload;
    },
    setPlanType: (state, { payload }: PayloadAction<string>) => {
      state.data.planType = payload;
    },
    setEmailAdmin: (state, { payload }: PayloadAction<string>) => {
      state.data.emailAdmin = payload;
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.data.name = payload;
    },
    setEmailBilling: (state, { payload }: PayloadAction<string>) => {
      state.data.emailBilling = payload;
    },
    setProductId: (state, { payload }: PayloadAction<string>) => {
      state.data.productId = payload;
    },
    setPriceId: (state, { payload }: PayloadAction<string>) => {
      state.data.priceId = payload;
    },
    setQuantity: (state, { payload }: PayloadAction<string>) => {
      state.data.quantity = payload;
    },
    setRole: (state, { payload }: PayloadAction<RolesType>) => {
      state.data.role = payload;
    },
    setPrivacy: (state, { payload }: PayloadAction<boolean>) => {
      state.data.privacy = payload;
    },
    showAlert: (state, { payload }: PayloadAction<string>) => {
      state.errorMessage = payload;
      state.showAlert = true;
    },
    hideAlert: (state) => {
      state.errorMessage = '';
      state.showAlert = false;
    },
    clearPurchase: (state) => {
      state.activeStep = 0;
      state.data.billingCycle = 'monthly';
      state.data.emailAdmin = '';
      state.data.emailBilling = '';
      state.data.productId = '';
      state.data.priceId = '';
      state.data.quantity = '5';
      state.data.role = 'administrator';
      state.data.privacy = false;
      state.subscription = null;
    },
  },
  extraReducers: (builder) => {
    // GET-PRODUCTS ********************************************************************************
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.data.planType = payload[0].title;
    });
    builder.addCase(getProducts.rejected, (state) => {
      state.data.planType = '';
    });
    // CREATE-CUSTOMER-SUBSCRIPTION ****************************************************************
    builder.addCase(createCustomerSubscription.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(createCustomerSubscription.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.subscription = payload;
    });
    builder.addCase(createCustomerSubscription.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // ATTACH-CUSTOMER-SUBSCRIPTION ****************************************************************
    builder.addCase(attachCustomerSubscription.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(attachCustomerSubscription.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.subscription = payload;
    });
    builder.addCase(attachCustomerSubscription.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // LOGOUT **************************************************************************************
    builder.addCase(logout.fulfilled, (state) => initialState);
  },
});

export const {
  nextStep,
  prevStep,
  resetStep,
  setBillingCycle,
  setPlanType,
  setEmailAdmin,
  setName,
  setEmailBilling,
  setProductId,
  setPriceId,
  setQuantity,
  setRole,
  setPrivacy,
  showAlert,
  hideAlert,
  clearPurchase,
} = purchaseSlice.actions;

export default purchaseSlice.reducer;
