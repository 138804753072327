import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../thunks/authThunk';
import {
  createNewAccount,
  deactivateAccount,
  getOrgAccounts,
  reactivateAccount,
  updateAccountRoles,
} from '../thunks/dashboardThunk';
import { DashboardState } from './dashboardSlice.types';

const initialState: DashboardState = {
  loading: false,
  errorMessage: '',
  showAlert: false,
  accounts: undefined,
  inactiveAccounts: undefined,
  openSubscription: 0,
  totalSubscription: 0,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    // GET-ORG-ACCOUNT *****************************************************************************
    builder.addCase(getOrgAccounts.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(getOrgAccounts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.accounts = payload.accounts;
      state.inactiveAccounts = payload.inactive_accounts;
      state.openSubscription = payload.open_subscription_quantity;
      state.totalSubscription = payload.total_subscription_quantity;
    });
    builder.addCase(getOrgAccounts.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // CREATE-NEW-ACCOUNT **************************************************************************
    builder.addCase(createNewAccount.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(createNewAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.accounts = payload.accounts;
      state.inactiveAccounts = payload.inactive_accounts;
      state.openSubscription = payload.open_subscription_quantity;
      state.totalSubscription = payload.total_subscription_quantity;
    });
    builder.addCase(createNewAccount.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // UPDATE-ACCOUNT-ROLES ************************************************************************
    builder.addCase(updateAccountRoles.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(updateAccountRoles.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.accounts = payload.accounts;
      state.inactiveAccounts = payload.inactive_accounts;
      state.openSubscription = payload.open_subscription_quantity;
      state.totalSubscription = payload.total_subscription_quantity;
    });
    builder.addCase(updateAccountRoles.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // DEACTIVATE-ACCOUNT **************************************************************************
    builder.addCase(deactivateAccount.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(deactivateAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.accounts = payload.accounts;
      state.inactiveAccounts = payload.inactive_accounts;
      state.openSubscription = payload.open_subscription_quantity;
      state.totalSubscription = payload.total_subscription_quantity;
    });
    builder.addCase(deactivateAccount.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // REACTIVATE-ACCOUNT **************************************************************************
    builder.addCase(reactivateAccount.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
      state.showAlert = false;
    });
    builder.addCase(reactivateAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = '';
      state.showAlert = false;
      state.accounts = payload.accounts;
      state.inactiveAccounts = payload.inactive_accounts;
      state.openSubscription = payload.open_subscription_quantity;
      state.totalSubscription = payload.total_subscription_quantity;
    });
    builder.addCase(reactivateAccount.rejected, (state, { payload }) => {
      state.loading = false;
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // LOGOUT **************************************************************************************
    builder.addCase(logout.fulfilled, (state) => initialState);
  },
});

export const { clearDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
