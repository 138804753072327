import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../thunks/authThunk';
import { deleteGithubWorkspace, getGithubState, importGithubWorkspace } from '../thunks/githubThunk';
import type { GithubState, GithubUser } from './githubSlice.types';

const initialState: GithubState = {
  loading: false,
  errorMessage: '',
  showAlert: false,
};

const githubSlice = createSlice({
  name: 'github',
  initialState,
  reducers: {
    setGithubRepoSelectorVisible: (_state, { payload }: PayloadAction<boolean>) => {
      _state.loading = payload;
    },

    clearGithubServerError: (state) => {
      state.showAlert = false;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGithubState.fulfilled, (state, { payload }: PayloadAction<GithubUser>) => {
      if (payload.user) {
        state.user = payload.user;
      }
      state.loading = false;
    });
    builder.addCase(getGithubState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGithubState.rejected, (state, { payload }) => {
      state.loading = false;
      // TODO
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });

    builder.addCase(importGithubWorkspace.fulfilled, (state, { payload }: PayloadAction<GithubUser>) => {
      if (payload.user) {
        state.user = payload.user;
      }
      state.loading = false;
    });
    builder.addCase(importGithubWorkspace.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(importGithubWorkspace.rejected, (state, { payload }) => {
      state.loading = false;
      // TODO
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });

    builder.addCase(deleteGithubWorkspace.fulfilled, (state, { payload }: PayloadAction<GithubUser>) => {
      if (payload.user) {
        state.user = payload.user;
      }
      state.loading = false;
    });
    builder.addCase(deleteGithubWorkspace.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteGithubWorkspace.rejected, (state, { payload }) => {
      state.loading = false;
      // TODO
      state.showAlert = true;
      state.errorMessage = payload ?? 'Something went wrong.';
    });
    // LOGOUT **************************************************************************************
    builder.addCase(logout.fulfilled, (state) => initialState);
  },
});

export const { setGithubRepoSelectorVisible, clearGithubServerError } = githubSlice.actions;

export default githubSlice.reducer;
