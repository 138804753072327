import { AppRoute } from '../constants/appRoutes';
import { AccountRolesType, AccountType } from '../store/slices/authSlice.types';
import type { BillingCycleType } from '../store/slices/purchaseSlice.types';
import store from '../store/store';

export const getPrice = (title?: string, billing?: BillingCycleType): number => {
  const { products, purchase } = store.getState();
  if (!products.data.length) return 0;
  if (title === undefined) title = purchase.data.planType;
  if (billing === undefined) billing = purchase.data.billingCycle;
  const prod = products.data.find((p) => p.title === title);
  if (!prod) {
    return 0;
  }
  if (billing === 'monthly') {
    const price = prod.prices.find((p) => p.interval === 'month');
    if (!price) {
      return 0;
    }
    return Number(price.unit_price) / 100;
  }
  const price = prod.prices.find((p) => p.interval === 'year');
  if (!price) {
    return 0;
  }
  return +`${Math.round(Number(`${price.unit_price / 1200}e+2`))}e-2`;
};

export const getTotalPrice = (): number => {
  const { purchase } = store.getState();
  if (purchase.data.billingCycle === 'monthly') {
    return getPrice() * Number(purchase.data.quantity);
  }
  return getPrice() * 12 * Number(purchase.data.quantity);
};

export const getLinkName = (linkName: string): string => {
  return `${linkName.charAt(1).toUpperCase()}${linkName.slice(2)}`;
};

export const freeUserVisible = (appRoute: AppRoute, account: AccountType): boolean => {
  return 'freeUserAccess' in appRoute && appRoute.freeUserAccess && account.roles.length === 0;
};

export const hasRoles = (
  appRoute: AppRoute,
  account: AccountType,
  // requiredRoles: Readonly<AccountRolesType> | undefined,
  // roles: AccountRolesType | undefined,
): boolean => {
  let result = false;
  if (!appRoute.roles.length || freeUserVisible(appRoute, account)) return true;
  if (appRoute.roles.length && account.roles.length) {
    result = appRoute.roles.some((r) => account.roles.includes(r));
  }
  return result;
};

type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K;
} extends { [_ in keyof T]: infer U }
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {} extends U
    ? never
    : U
  : never;

export const typedKeys = <T extends Record<string, any>>(input: T): KnownKeys<T>[] => {
  return Object.keys(input) as KnownKeys<T>[];
};

// const passwordRegExp = new RegExp('[\\w\\W]{10,32}');
// export const isPasswordValid = (password: string): boolean => {
//   return passwordRegExp.test(password);
// };
// const validatePassword = (): boolean => {
//   return !!data.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,24}$/);
// };
