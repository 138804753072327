import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  checkAuth,
  forgotPassword,
  login,
  loginWithGithub,
  logout,
  registration,
  resetPassword,
  updatePassword,
} from '../thunks/authThunk';
import type { IChangePasswordType } from '../thunks/authThunk.types';
import { getOrgAccounts, updateAccountRoles } from '../thunks/dashboardThunk';
import type { IAuthOperation, AuthState, PayloadActionKeyType, IUpdatePasswordOperation } from './authSlice.types';

const operationTemplate: IAuthOperation = {
  loading: false,
  errorMessage: '',
  showAlert: false,
} as const;

export const initialUpdatePasswords: IChangePasswordType = {
  old_password: '',
  new_password: '',
};

const UpdatePasswordTemplate: IUpdatePasswordOperation = {
  loading: false,
  errorMessage: '',
  showAlert: false,
  passwords: { ...initialUpdatePasswords },
} as const;

const initialState: AuthState = {
  isAuthenticated: undefined,
  sessionToken: '',
  user: {
    account_id: '',
    roles: [],
    email: undefined,
    sub_tier: undefined,
    github_username: undefined,
  },
  registration: { ...operationTemplate },
  login: { ...operationTemplate },
  checkAuth: { ...operationTemplate },
  forgotPassword: { ...operationTemplate },
  resetPassword: { ...operationTemplate },
  updatePassword: { ...UpdatePasswordTemplate },
  logout: { ...operationTemplate },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    setPasswords: (state, { payload }: PayloadAction<{ [key: string]: string }>) => {
      state.updatePassword.passwords = { ...state.updatePassword.passwords, ...payload };
    },
    setShowSnackbar: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: PayloadActionKeyType;
        value: boolean;
      }>,
    ) => {
      state[payload.key].showAlert = payload.value;
    },
    setAuthErrorByKey: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: PayloadActionKeyType;
        value: string;
      }>,
    ) => {
      state[payload.key].errorMessage = payload.value;
    },
    clearAuth: () => initialState,
  },
  extraReducers: (builder) => {
    // REGISTRATION ********************************************************************************
    builder.addCase(registration.pending, (state) => {
      state.registration.loading = true;
      state.registration.errorMessage = '';
      state.registration.showAlert = false;
    });
    builder.addCase(registration.fulfilled, (state, { payload }) => {
      state.registration.loading = false;
      state.isAuthenticated = true;
      state.sessionToken = payload.sessionToken;
      state.user = payload.user;
      state.login.errorMessage = '';
    });
    builder.addCase(registration.rejected, (state, { payload }) => {
      state.isAuthenticated = false;
      state.registration.loading = false;
      state.registration.showAlert = true;
      state.registration.errorMessage = payload ?? 'Something went wrong.';
    });
    // LOGIN ***************************************************************************************
    builder.addCase(login.pending, (state) => {
      state.login.loading = true;
      state.login.errorMessage = '';
      state.login.showAlert = false;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.sessionToken = payload.sessionToken;
      state.user = payload.user;
      state.login.loading = false;
      state.login.errorMessage = '';
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.isAuthenticated = false;
      state.login.loading = false;
      state.login.showAlert = true;
      state.login.errorMessage = payload ?? 'Something went wrong.';
    });
    // GITHUB AUTH *********************************************************************************
    builder.addCase(loginWithGithub.pending, (state) => {
      state.login.loading = true;
    });
    builder.addCase(loginWithGithub.fulfilled, (state) => {
      state.login.loading = false;
    });
    builder.addCase(loginWithGithub.rejected, (state, { payload }) => {
      state.login.loading = false;
      state.login.showAlert = true;
      state.login.errorMessage = payload ?? 'Something went wrong.';
    });
    // CHECK-AUTH **********************************************************************************
    builder.addCase(checkAuth.pending, (state) => {
      state.checkAuth.loading = true;
      state.checkAuth.errorMessage = '';
      state.checkAuth.showAlert = false;
    });
    builder.addCase(checkAuth.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.sessionToken = payload.sessionToken;
      state.user = payload.user;
      state.checkAuth.loading = false;
      state.checkAuth.errorMessage = '';
    });
    builder.addCase(checkAuth.rejected, (state, { payload }) => {
      state.isAuthenticated = false;
      state.checkAuth.loading = false;
      state.checkAuth.showAlert = true;
      state.checkAuth.errorMessage = payload ?? 'Something went wrong.';
    });
    // LOGOUT **************************************************************************************
    builder.addCase(logout.pending, (state) => {
      state.logout.loading = true;
      state.logout.errorMessage = '';
      state.logout.showAlert = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      return {
        ...initialState,
        isAuthenticated: false,
      };
    });
    builder.addCase(logout.rejected, (state, { payload }) => {
      state.logout.loading = false;
      state.logout.showAlert = true;
      state.logout.errorMessage = payload ?? 'Something went wrong.';
    });
    // FORGOT-PASSWORD *****************************************************************************
    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPassword.loading = true;
      state.forgotPassword.errorMessage = '';
      state.forgotPassword.showAlert = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.errorMessage = '';
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.showAlert = true;
      state.forgotPassword.errorMessage = payload ?? 'Something went wrong.';
    });
    // RESET-PASSWORD *****************************************************************************
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPassword.loading = true;
      state.resetPassword.errorMessage = '';
      state.resetPassword.showAlert = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.resetPassword.loading = false;
      state.resetPassword.errorMessage = '';
    });
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.resetPassword.loading = false;
      state.resetPassword.showAlert = true;
      state.resetPassword.errorMessage = payload ?? 'Something went wrong.';
    });
    // UPDATE-PASSWORD *****************************************************************************
    builder.addCase(updatePassword.pending, (state) => {
      state.updatePassword.loading = true;
      state.updatePassword.errorMessage = '';
      state.updatePassword.showAlert = false;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.updatePassword.loading = false;
      state.updatePassword.errorMessage = '';
      state.updatePassword.showAlert = false;
      state.updatePassword.passwords = { ...initialUpdatePasswords };
    });
    builder.addCase(updatePassword.rejected, (state, { payload }) => {
      state.updatePassword.loading = false;
      state.updatePassword.showAlert = true;
      state.updatePassword.errorMessage = payload ?? 'Something went wrong.';
    });
    // Org Account Info may include changes to the current user
    builder.addCase(getOrgAccounts.fulfilled, (state, { payload }) => {
      const matchingCurrentUser = payload.accounts.find((account) => account.account_id === state.user.account_id);
      if (matchingCurrentUser) {
        state.user = matchingCurrentUser;
      }
    });
    builder.addCase(updateAccountRoles.fulfilled, (state, { payload }) => {
      const matchingCurrentUser = payload.accounts.find((account) => account.account_id === state.user.account_id);
      if (matchingCurrentUser) {
        state.user = matchingCurrentUser;
      }
    });
  },
});

export const { setIsAuthenticated, setPasswords, setShowSnackbar, setAuthErrorByKey, clearAuth } = authSlice.actions;

export default authSlice.reducer;
