import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import axios from '../axiosConfig/axiosConfig';
import type { PostResponse } from '../axiosConfig/types';
// eslint-disable-next-line import/no-cycle
import { nextStep } from '../slices/purchaseSlice';
import type { PurchaseState } from '../slices/purchaseSlice.types';
import type { Store } from '../store';
import { checkAuth } from './authThunk';

export interface ICustomer {
  emailAdmin: string;
  emailBilling?: string;
  name: string;
}

interface ICreateCustomerSubscriptionArgs {
  stripe: Stripe;
  elements: StripeElements;
  purchase: PurchaseState;
}

export const createCustomerSubscription = createAsyncThunk<
  PostResponse<'/create-customer-subscription'>,
  ICreateCustomerSubscriptionArgs,
  {
    rejectValue: string;
    dispatch: ThunkDispatch<any, any, any>;
  }
>(
  'purchase/createCustomerSubscription',
  async ({ stripe, elements, purchase }: ICreateCustomerSubscriptionArgs, { rejectWithValue, dispatch }) => {
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      // TODO: reject with proper error message
      return rejectWithValue('Something went wrong.');
    }
    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (paymentMethod.error) {
      return rejectWithValue(paymentMethod.error.message ?? 'Something went wrong.');
    }

    try {
      // Create customer
      // const customer: ICustomer = {
      //   emailAdmin: purchase.data.emailAdmin,
      //   emailBilling: purchase.data.emailBilling,
      //   name: purchase.data.name,
      // };
      // const customerResponse = await axios.post('/customers', customer);
      try {
        const response = await axios.post('/create-customer-subscription', {
          emailAdmin: purchase.data.emailAdmin,
          emailBilling: purchase.data.emailBilling,
          name: purchase.data.name,
          paymentMethodId: paymentMethod.paymentMethod?.id ?? '',
          priceId: purchase.data.priceId,
          quantity: Number(purchase.data.quantity),
        });
        // const response = await axios.post('/create-subscription', {
        //   customerId: customerResponse.data.customer.id,
        //   paymentMethodId: paymentMethod.paymentMethod?.id ?? '',
        //   priceId: purchase.data.priceId,
        //   quantity: Number(purchase.data.quantity),
        // });
        if (response.status === 202) {
          // 4100 0000 0000 0019  Results in a charge with a risk_level of highest.
          return rejectWithValue((response.data as any).message);
        }
        dispatch(nextStep());
        return response.data;
      } catch (e) {
        return rejectWithValue(e.response.data.message);
      }
    } catch (e) {
      // console.log(JSON.stringify(e, null, 2));
      return rejectWithValue(e.message);
    }
  },
);

export const attachCustomerSubscription = createAsyncThunk<
  PostResponse<'/attach-customer-subscription'>,
  ICreateCustomerSubscriptionArgs,
  {
    rejectValue: string;
    dispatch: ThunkDispatch<any, any, any>;
    state: Store;
  }
>(
  'purchase/attachCustomerSubscription',
  async ({ stripe, elements, purchase }: ICreateCustomerSubscriptionArgs, { rejectWithValue, dispatch, getState }) => {
    const { sessionToken } = getState().auth;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      // TODO: reject with proper error message
      return rejectWithValue('Something went wrong.');
    }
    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (paymentMethod.error) {
      return rejectWithValue(paymentMethod.error.message ?? 'Something went wrong.');
    }

    try {
      // Create customer
      // const customer: ICustomer = {
      //   emailAdmin: purchase.data.emailAdmin,
      //   emailBilling: purchase.data.emailBilling,
      //   name: purchase.data.name,
      // };
      // const customerResponse = await axios.post('/customers', customer);
      try {
        const response = await axios.post('/attach-customer-subscription', {
          sessionToken,
          emailAdmin: purchase.data.emailAdmin,
          emailBilling: purchase.data.emailBilling,
          name: purchase.data.name,
          paymentMethodId: paymentMethod.paymentMethod?.id ?? '',
          priceId: purchase.data.priceId,
          quantity: Number(purchase.data.quantity),
        });
        // const response = await axios.post('/create-subscription', {
        //   customerId: customerResponse.data.customer.id,
        //   paymentMethodId: paymentMethod.paymentMethod?.id ?? '',
        //   priceId: purchase.data.priceId,
        //   quantity: Number(purchase.data.quantity),
        // });
        if (response.status === 202) {
          // 4100 0000 0000 0019  Results in a charge with a risk_level of highest.
          return rejectWithValue((response.data as any).message);
        }
        dispatch(nextStep());
        dispatch(checkAuth());
        return response.data;
      } catch (e) {
        return rejectWithValue(e.response.data.message);
      }
    } catch (e) {
      // console.log(JSON.stringify(e, null, 2));
      return rejectWithValue(e.message);
    }
  },
);
