import React, { Component, ErrorInfo, ReactNode } from 'react';
import Error from '../../pages/Error';

interface Props {
  children: ReactNode;
}

interface ErrorAppState {
  hasError: false;
  error?: undefined;
}

interface CleanAppState {
  hasError: true;
  error: Error;
}

type AppState = ErrorAppState | CleanAppState;

export default class ErrorBoundary extends Component<Props, AppState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): { hasError: boolean; error: Error } {
    // Update state so next render shows fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    // TODO: bugsnag?
    // eslint-disable-next-line no-console
    console.log('Log for dev-support:', error, info);
  }

  render(): JSX.Element {
    const { hasError } = this.state;
    const { error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Error error={error} />;
    }

    return <>{children}</>;
  }
}
