import { Avatar, Box, Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container/Container';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { AccountOutline } from 'mdi-material-ui';
import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import GithubAuthButton from '../components/Github/GithubAuthButton';
import appRoutes from '../constants/appRoutes';
import { setShowSnackbar } from '../store/slices/authSlice';
import type { SignInCredentials } from '../store/slices/authSlice.types';
import type { RootState } from '../store/store';
import { login, loginWithGithub } from '../store/thunks/authThunk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signIn: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      width: theme.spacing(8),
      height: theme.spacing(8),
      backgroundColor: theme.palette.secondary.main,
    },
    icon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    submit: {
      margin: theme.spacing(1, 0),
    },
    github: {
      margin: theme.spacing(1, 0),
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -16,
      marginLeft: -16,
    },
    alert: {
      marginTop: theme.spacing(1),
    },
  }),
);

const Login = (): JSX.Element => {
  const classes = useStyles();

  const { auth } = useSelector((store: RootState) => store);

  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState<SignInCredentials>({
    username: '',
    password: '',
  });

  function onAlertClose() {
    if (auth.login.showAlert) dispatch(setShowSnackbar({ key: 'login', value: false }));
  }

  function handleInputs(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    e.persist();
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
    onAlertClose();
  }

  async function onSubmitClick(e: SyntheticEvent): Promise<void> {
    e.preventDefault();
    await dispatch(login(credentials));
  }

  function onLoginWithGithubClick() {
    dispatch(loginWithGithub());
  }

  return (
    <Container maxWidth="xs">
      <Box className={classes.signIn}>
        <Avatar className={classes.avatar}>
          <AccountOutline className={classes.icon} />
        </Avatar>
        <Typography component="h3" variant="h5">
          User login
        </Typography>
        <form className={classes.form} onSubmit={onSubmitClick}>
          <TextField
            id="username"
            name="username"
            label="Email Address"
            type="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoComplete="username"
            autoFocus
            value={credentials.username}
            onChange={handleInputs}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            required
            variant="outlined"
            fullWidth
            margin="normal"
            helperText="Password must contain at least 10 characters"
            value={credentials.password}
            onChange={handleInputs}
            FormHelperTextProps={{
              classes: makeStyles((theme: Theme) => ({
                root: {
                  visibility:
                    credentials.password.length === 0 || credentials.password.length > 9 ? 'hidden' : 'visible',
                  color: theme.palette.error.main,
                },
              }))(),
            }}
          />
          <div className={classes.wrapper}>
            <Button
              id="btnLogin"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              disabled={auth.login.loading || !(credentials.password.length > 9)}
            >
              Login
            </Button>
            <GithubAuthButton
              id="btnGithubLogin"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={auth.login.loading}
              className={classes.github}
            >
              Login with Github
            </GithubAuthButton>
            {auth.login.loading && <CircularProgress size={32} className={classes.buttonProgress} />}
          </div>
          <Grid container justify="space-between">
            <Grid item>
              <NavLink to={appRoutes.FORGOT_PASSWORD.url}>Forgot password?</NavLink>
            </Grid>
            <Grid item>
              <NavLink to={appRoutes.REGISTRATION.url}>Don&apos;t have an account?</NavLink>
            </Grid>
          </Grid>
          {auth.login.showAlert && (
            <Alert variant="filled" severity="error" onClose={onAlertClose} className={classes.alert}>
              {auth.login.errorMessage}
            </Alert>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default Login;
