import React, { FC, useState } from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import {
  AppBar,
  Avatar,
  Box,
  ClickAwayListener,
  Container,
  createStyles,
  Grow,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  Theme,
  Toolbar,
} from '@material-ui/core';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import { AccountTie, Menu } from 'mdi-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { setCloseAlert } from '../../store/slices/alertSlice';
import appRoutes from '../../constants/appRoutes';
import { logout } from '../../store/thunks/authThunk';
import { getLinkName } from '../utils';
import type { RootState } from '../../store/store';

interface Props {
  children: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      fontSize: theme.typography.body1.fontSize,
      marginLeft: theme.spacing(1),
    },
    logo: {
      width: 46,
      // color: theme.palette.primary.contrastText,
      // fontWeight: theme.typography.fontWeightBold,
      // marginRight: theme.spacing(3),
      // textDecoration: 'none',
      // '&:hover': {
      //   textDecoration: 'none',
      // },
    },
    link: {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: theme.spacing(0.5),
      padding: theme.spacing(1),
      transition: 'color 0.3s ease',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.light,
        textDecoration: 'none',
      },
      '&.active': {
        color: theme.palette.secondary.light,
      },
    },
    btnLink: {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: theme.spacing(0.5),
      padding: theme.spacing(1),
      transition: 'color 0.3s ease, border-color 0.3s ease',
      borderWidth: 1,
      borderColor: theme.palette.primary.contrastText,
      borderStyle: 'solid',
      borderRadius: theme.shape.borderRadius,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
      '&.active': {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
    },
    mobile: {
      display: 'none',
    },
    mobileLink: {
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(1),
      transition: 'color 0.3s ease',
      // textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.light,
        textDecoration: 'none',
      },
      '&.active': {
        color: theme.palette.secondary.light,
      },
    },
    mobileBtnLink: {
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(1),
      transition: 'color 0.3s ease, border-color 0.3s ease',
      borderWidth: 1,
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderRadius: theme.shape.borderRadius,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
      '&.active': {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
    },
    [theme.breakpoints.down('xs')]: {
      toolBar: {
        justifyContent: 'space-between',
      },
      desktop: {
        display: 'none',
      },
      mobile: {
        display: 'flex',
      },
    },
  }),
);

type MenuStateType = {
  mobileMenu: boolean;
  userMenu: boolean;
};

function MuiAlert(props: AlertProps): JSX.Element {
  return <Alert elevation={6} variant="filled" {...props} />;
}

function HideOnScroll(props: Props): JSX.Element {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header: FC = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { alert, auth } = useSelector((store: RootState) => store);
  const [menuState, setMenuState] = useState<MenuStateType>({
    mobileMenu: false,
    userMenu: false,
  });

  const userMenuRef = React.useRef<HTMLButtonElement>(null);
  const mobileMenuRef = React.useRef<HTMLButtonElement>(null);

  const navRoutes: Array<string> = [
    // appRoutes.PRODUCT.url,
    appRoutes.PRICING.url,
    // appRoutes.CONTACTS.url,
    appRoutes.DOWNLOADS.url,
  ];

  const docsUrl = process.env.REACT_APP_DOCS_URL;
  const blogUrl = process.env.REACT_APP_BLOG_URL;

  function toggleUserMenu(): void {
    setMenuState((prevMenuState) => {
      return {
        ...prevMenuState,
        userMenu: !prevMenuState.userMenu,
      };
    });
  }

  function toggleMobileMenu(): void {
    setMenuState((prevMenuState) => {
      return {
        ...prevMenuState,
        mobileMenu: !prevMenuState.mobileMenu,
      };
    });
  }

  function closeUserMenu(e: React.MouseEvent<EventTarget>): void {
    if (userMenuRef.current && userMenuRef.current.contains(e.target as HTMLElement)) {
      return;
    }

    setMenuState((prevMenuState) => {
      return {
        ...prevMenuState,
        userMenu: false,
      };
    });
  }

  function closeMobileMenu(e: React.MouseEvent<EventTarget>): void {
    if (mobileMenuRef.current && mobileMenuRef.current.contains(e.target as HTMLElement)) {
      return;
    }

    setMenuState((prevMenuState) => {
      return {
        ...prevMenuState,
        mobileMenu: false,
      };
    });
  }

  function handleUserMenuKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuState((prevMenuState) => {
        return {
          ...prevMenuState,
          userMenu: false,
        };
      });
    }
  }

  function handleMobileListKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuState((prevMenuState) => {
        return {
          ...prevMenuState,
          mobileMenu: false,
        };
      });
    }
  }

  function onLogoutClick(): void {
    setMenuState(() => {
      return {
        mobileMenu: false,
        userMenu: false,
      };
    });

    dispatch(logout());
  }

  function handleCloseAlert(): void {
    dispatch(setCloseAlert());
  }

  return (
    <>
      <HideOnScroll>
        <AppBar>
          <Container>
            <Toolbar id="tool-bar" disableGutters className={classes.toolBar}>
              <Avatar component={NavLink} to="/" src="/swirl1.png" className={classes.logo} />
              <Box className={classes.desktop}>
                <Box>
                  {navRoutes.map((route) => (
                    <NavLink key={route} id={`navLink${getLinkName(route)}`} to={route} className={classes.link}>
                      {getLinkName(route)}
                    </NavLink>
                  ))}
                  <Link href={docsUrl} target="_blank" className={classes.link}>
                    Documentation
                  </Link>
                  <Link href={blogUrl} target="_blank" className={classes.link}>
                    Blog
                  </Link>
                </Box>
                {auth.isAuthenticated === undefined ? null : auth.isAuthenticated ? (
                  <>
                    <IconButton id="btnAccount" ref={userMenuRef} color="inherit" onClick={toggleUserMenu}>
                      <AccountTie />
                    </IconButton>
                    <Popper
                      open={menuState.userMenu}
                      anchorEl={userMenuRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper elevation={5}>
                            <ClickAwayListener onClickAway={closeUserMenu}>
                              <MenuList
                                id="accountList"
                                autoFocusItem={menuState.userMenu}
                                onKeyDown={handleUserMenuKeyDown}
                              >
                                <MenuItem
                                  id="navLinkDashboard"
                                  component={NavLink}
                                  to={appRoutes.DASHBOARD.url}
                                  className={classes.mobileLink}
                                  onClick={closeUserMenu}
                                >
                                  Dashboard
                                </MenuItem>
                                <MenuItem id="btnLogout" className={classes.mobileBtnLink} onClick={onLogoutClick}>
                                  Logout
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <Box>
                    <NavLink id="navLinkLogin" to={appRoutes.LOGIN.url} className={classes.btnLink}>
                      Login
                    </NavLink>
                    <NavLink id="navLinkRegistration" to={appRoutes.REGISTRATION.url} className={classes.btnLink}>
                      Registration
                    </NavLink>
                  </Box>
                )}
              </Box>
              <Box className={classes.mobile}>
                <IconButton
                  ref={mobileMenuRef}
                  color="inherit"
                  aria-label="show more"
                  aria-haspopup="true"
                  onClick={toggleMobileMenu}
                >
                  <Menu />
                </IconButton>
                <Popper
                  open={menuState.mobileMenu}
                  anchorEl={mobileMenuRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper elevation={5}>
                        <ClickAwayListener onClickAway={closeMobileMenu}>
                          <MenuList
                            id="menu-list"
                            autoFocusItem={menuState.mobileMenu}
                            onKeyDown={handleMobileListKeyDown}
                          >
                            {navRoutes.map((route) => (
                              <MenuItem
                                key={route}
                                component={NavLink}
                                to={route}
                                className={classes.mobileLink}
                                onClick={closeMobileMenu}
                              >
                                {getLinkName(route)}
                              </MenuItem>
                            ))}
                            <MenuItem
                              component={Link}
                              href={docsUrl}
                              target="_blank"
                              className={classes.mobileLink}
                              onClick={closeMobileMenu}
                            >
                              Documentation
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              href={blogUrl}
                              target="_blank"
                              className={classes.mobileLink}
                              onClick={closeMobileMenu}
                            >
                              Blog
                            </MenuItem>
                            {auth.isAuthenticated === undefined ? null : auth.isAuthenticated ? (
                              <div>
                                <MenuItem
                                  component={NavLink}
                                  to={appRoutes.DASHBOARD.url}
                                  className={classes.mobileLink}
                                  onClick={closeMobileMenu}
                                >
                                  Dashboard
                                </MenuItem>
                                <MenuItem className={classes.mobileBtnLink} onClick={onLogoutClick}>
                                  Logout
                                </MenuItem>
                              </div>
                            ) : (
                              <div>
                                <MenuItem
                                  id="navLinkLoginMobile"
                                  component={NavLink}
                                  to={appRoutes.LOGIN.url}
                                  className={classes.mobileBtnLink}
                                  onClick={closeMobileMenu}
                                >
                                  Login
                                </MenuItem>
                                <MenuItem
                                  id="navLinkRegistrationMobile"
                                  component={NavLink}
                                  to={appRoutes.REGISTRATION.url}
                                  className={classes.mobileBtnLink}
                                  onClick={closeMobileMenu}
                                >
                                  Registration
                                </MenuItem>
                              </div>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />
      <Snackbar open={alert.open} autoHideDuration={7000} onClose={handleCloseAlert}>
        <MuiAlert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Header;
