import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axiosConfig/axiosConfig';
import { PostResponse } from '../axiosConfig/types';
import { AccountRolesType } from '../slices/authSlice.types';
import type { Store } from '../store';

export const getOrgAccounts = createAsyncThunk<
  PostResponse<'/admin/get-org-accounts'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('dashboard/getOrgAccounts', async (_args, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/get-org-accounts', {
      sessionToken,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const createNewAccount = createAsyncThunk<
  PostResponse<'/admin/assign-license-emails'>,
  { email: string; roles: AccountRolesType },
  {
    rejectValue: string;
    state: Store;
  }
>('dashboard/createNewAccount', async ({ email, roles }, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/assign-license-emails', {
      sessionToken,
      emails_with_roles: [{ email, roles }],
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const updateAccountRoles = createAsyncThunk<
  PostResponse<'/admin/grant-email-account-roles'>,
  { email: string; roles: AccountRolesType },
  {
    rejectValue: string;
    state: Store;
  }
>('dashboard/updateAccountRoles', async ({ email, roles }, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/grant-email-account-roles', {
      sessionToken,
      grant_roles: [{ email, roles }],
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const deactivateAccount = createAsyncThunk<
  PostResponse<'/admin/deactivate-account'>,
  { id: string },
  {
    rejectValue: string;
    state: Store;
  }
>('dashboard/deactivateAccount', async ({ id }, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/deactivate-account', {
      sessionToken,
      deactivate_account_id: id,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const reactivateAccount = createAsyncThunk<
  PostResponse<'/admin/reactivate-account'>,
  { id: string },
  {
    rejectValue: string;
    state: Store;
  }
>('dashboard/reactivateAccount', async ({ id }, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/reactivate-account', {
      sessionToken,
      reactivate_account_id: id,
      // roles: ['developer'],
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const createPortalSession = createAsyncThunk<
  PostResponse<'/admin/create-portal-session'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('dashboard/createPortalSession', async (_, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/create-portal-session', { sessionToken });
    window.location.href = response.data.redirect;
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});
