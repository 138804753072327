import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import appRoutes, { AppRoute } from '../../constants/appRoutes';
import Login from '../../pages/Login';
import type { RootState } from '../../store/store';
import { freeUserVisible, hasRoles } from '../utils';

type PrivateRouteProps<T extends AppRoute> = {
  appRoute: T;
  path: T['url'];
  component: FC;
  exact?: boolean;
};

const PrivateRoute = <T extends AppRoute>({
  appRoute,
  exact = false,
  path,
  component,
}: PrivateRouteProps<T>): JSX.Element | null => {
  const { auth } = useSelector((store: RootState) => store);
  const { isAuthenticated, user } = auth;

  if (isAuthenticated === false) {
    return <Login />;
  }

  if (isAuthenticated) {
    if (hasRoles(appRoute, user)) {
      return <Route exact={exact} path={appRoute.url} component={component} />;
    }
    return <Typography>You do not have the roles to access this URL!</Typography>;
  }

  return null;
};

export default PrivateRoute;
