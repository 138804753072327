import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axiosConfig/axiosConfig';
import { PostResponse } from '../axiosConfig/types';
import type { Store } from '../store';
import { GithubRepoImport } from './types';

export const authWithGithub = createAsyncThunk<
  PostResponse<'/admin/github-auth'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('github/authWithGithub', async (_args, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/github-auth', {
      sessionToken,
    });
    window.location.href = response.data.redirect;
    return response.data;
  } catch (err) {
    if (err.response) {
      return rejectWithValue(err.response.data.message);
    }
    return rejectWithValue(err.message);
  }
});

export const getGithubState = createAsyncThunk<
  PostResponse<'/admin/get-github-state'>,
  void,
  {
    rejectValue: string;
    state: Store;
  }
>('github/getGithubState', async (_args, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/get-github-state', {
      sessionToken,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const importGithubWorkspace = createAsyncThunk<
  PostResponse<'/admin/import-github-workspace'>,
  GithubRepoImport,
  {
    rejectValue: string;
    state: Store;
  }
>('github/importGithubWorkspace', async (importRepoOptions, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/import-github-workspace', {
      sessionToken,
      ...importRepoOptions,
    });
    // const extendedWorkspaces = getExtendedWorkspaces(response.data.workspaces);
    // return { workspaces: extendedWorkspaces };
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});

export const deleteGithubWorkspace = createAsyncThunk<
  PostResponse<'/admin/delete-org-workspace'>,
  { workspaceId: string },
  {
    rejectValue: string;
    state: Store;
  }
>('github/deleteGithubWorkspace', async ({ workspaceId }, { rejectWithValue, getState }) => {
  try {
    const { sessionToken } = getState().auth;
    const response = await axios.post('/admin/delete-org-workspace', {
      sessionToken,
      workspace_id: workspaceId,
    });
    return response.data;
  } catch (err) {
    if (err.response) return rejectWithValue(err.response.data.message);
    return rejectWithValue(err.message);
  }
});
