import { CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import alertReducer from './slices/alertSlice';
import { AlertState } from './slices/alertSlice.types';
import authReducer from './slices/authSlice';
import { AuthState } from './slices/authSlice.types';
import dashboardReducer from './slices/dashboardSlice';
import { DashboardState } from './slices/dashboardSlice.types';
import productReducer from './slices/productsSlice';
import { ProductsState } from './slices/productsSlice.types';
import purchaseReducer from './slices/purchaseSlice';
import { PurchaseState } from './slices/purchaseSlice.types';
import githubReducer from './slices/githubSlice';
import { GithubState } from './slices/githubSlice.types';

export default function createRootReducer(
  history: History,
): Reducer<
  CombinedState<{
    router: RouterState;
    alert: AlertState;
    auth: AuthState;
    dashboard: DashboardState;
    products: ProductsState;
    purchase: PurchaseState;
    github: GithubState;
  }>
> {
  return combineReducers({
    router: connectRouter(history),
    alert: alertReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    products: productReducer,
    purchase: purchaseReducer,
    github: githubReducer,
  });
}
